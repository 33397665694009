import { BrowserRouter } from 'react-router-dom';
import { FC, useCallback } from 'react';
import {
  MutationCache,
  QueryCache,
  QueryClient,
  QueryClientProvider,
  useQueryErrorResetBoundary,
} from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { AppRoutes } from '../routes';
import { AuthProvider, Auth0ProviderWithRedirect } from '../modules/auth';
import AxiosWrapper from './AxiosWrapper';
import 'react-loading-skeleton/dist/skeleton.css';
import { SkeletonTheme } from 'react-loading-skeleton';
import { Toast } from '../ui/components/Toast';
import { RouterContextProvider } from '../modules/router/RouterProvider';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorPage from '../components/ErrorPage/ErrorPage';
import { AxiosError } from 'axios';
import * as Sentry from '@sentry/react';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // refetchOnMount: false,
      // retryOnMount: false,
      staleTime: 0,
      refetchOnWindowFocus: false,
      retry: import.meta.env.NODE_ENV !== 'production' ? false : 2,
      useErrorBoundary(error: AxiosError) {
        const responseCode = error?.response?.status;
        if (responseCode === 404) return false;
        return responseCode ? responseCode < 500 : false;
      },
      // retry: 0,
      // refetchOnWindowFocus: false,
      // cacheTime: 1000 * 60 * 10,
      // staleTime: 0,
      // staleTime: 1000 * 60 * 10,
    },
  },
  queryCache: new QueryCache({
    onError: (error: AxiosError, variables) => {
      Sentry.captureException({ ...error, payload: JSON.stringify(variables) });
    },
  }),
  mutationCache: new MutationCache({
    onError: (error: AxiosError, variables) => {
      Sentry.captureException({ ...error, variables: JSON.stringify(variables) });
    },
  }),
});

const SPA: FC = () => {
  const onSignOut = useCallback(() => {
    queryClient.clear();
    queryClient?.removeQueries({
      predicate: () => true,
    });
  }, []);
  const { reset } = useQueryErrorResetBoundary();

  return (
    <>
      <SkeletonTheme baseColor='#fff' highlightColor='#D1D5DB'>
        <BrowserRouter>
          <RouterContextProvider>
            <QueryClientProvider client={queryClient}>
              <Auth0ProviderWithRedirect>
                <AuthProvider onSignOut={onSignOut}>
                  <AxiosWrapper>
                    <ErrorBoundary onReset={reset} FallbackComponent={ErrorPage}>
                      <AppRoutes />
                      <Toast />
                      <ReactQueryDevtools initialIsOpen={false} position='bottom-right' />
                    </ErrorBoundary>
                  </AxiosWrapper>
                </AuthProvider>
              </Auth0ProviderWithRedirect>
            </QueryClientProvider>
          </RouterContextProvider>
        </BrowserRouter>
      </SkeletonTheme>
    </>
  );
};

export default SPA;
