import EmptyImage from '@/assets/empty.svg';
import classNames from 'classnames';
import { FC } from 'react';

interface IEmptyStateProps {
  message?: React.ReactNode;
  isRow?: boolean;
  customHeight?: number;
}

const EmptyState: FC<IEmptyStateProps> = ({ message = '', isRow, customHeight }) => {
  return (
    <div
      className={classNames('text-center', {
        'mx-auto flex flex-row items-center justify-center': isRow,
        'py-10': !isRow,
      })}>
      <img
        alt='Empty state'
        src={EmptyImage}
        width={customHeight ? customHeight : isRow ? 120 : 140}
        className={classNames({
          'mx-auto': !isRow,
        })}
      />

      <div className='text-gray mt-4 text-xs'>{message || 'Nothing to see over here'}</div>
    </div>
  );
};

export default EmptyState;
