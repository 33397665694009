import { useAuth0, Auth0Provider } from '@auth0/auth0-react';
import constate from 'constate';
import { jwtDecode } from 'jwt-decode';
import { useReducer, useCallback, useMemo, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useLocalStorage } from 'react-use';
import { IAuthState } from './types';
import { getUrlEnvironment, isDetailPageWithoutSearch } from '../../utils/helpers/common';
import { setTokenCookie } from '../../utils/helpers/cookies';
import { useRouter } from '../router/RouterProvider';

// export type ICanArgs = { permissions: string[]; roles?: string[] };
// type ICanUserFn = (check: ICanArgs) => boolean;

export interface IUseAuthStateProps {
  initialState?: IAuthState;
  onSignOut?: () => void;
  accessTokenKey?: string;
}

const initialAuthData: IAuthState = {
  access: '',
  decoded: {
    iss: '',
    sub: '',
    aud: [],
    iat: 0,
    exp: 0,
    azp: '',
    scope: '',
  },
  userLoggedOut: false,
  screeningLoader: false,
  isTabVisible: true,
  userProfile: {
    id: 0,
    first_name: '',
    last_name: '',
    timezone: '',
    name: '',
    email: '',
    role: 0,
    case_role: 0,
    language: '',
    domain: '',
    organization: '',
    organization_app_version: 0,
    can_generate_report: false,
    can_investigate: false,
    can_access_api: false,
    is_custom_tags_enabled: false,
    can_access_customers: false,
    can_enable_continuous_monitoring: false,
    is_multi_hop_enabled: false,
    allowed_currencies: [],
    continuous_monitoring_enabled: false,
    continuous_monitoring_level: 0,
    is_billing_ui_enabled: false,
    is_kybb_enabled: false,
    kybb_can_view_entity_addresses: false,
    organization_brand_logo: '',
    organization_brand_report_logo: '',
    organization_brand_color_code: '',
    workspaces: [],
    is_organization_admin: false,
    memberships: {},
    skip_alert_creation_modes: [],
  },
  accountPermissions: {
    id: 0,
    email: '',
    name: '',
    username: '',
    role_verbose: '',
    date_joined: '',
    is_authenticated: false,
    organization: '',
    billing_plan_name: '',
    language: '',
    timezone: '',
    created_at: '',
    user_hash: '',
    intercom_enabled: false,
    intercom_app_id: '',
    hotjar_enabled: false,
    hotjar_id: 0,
    gtm_enabled: false,
    gtm_id: '',
    mixpanel_enabled: false,
    mixpanel_token: '',
    logrocket_enabled: false,
    logrocket_token: '',
    server_env: '',
    fireblocks_enabled: false,
    zendesk_key: '',
  },
};

type SET_TOKEN_DATA = {
  type: 'SET_TOKEN_DATA';
  payload: Pick<IAuthState, 'access'>;
};

type UPDATE_TOKEN_DATA = {
  type: 'UPDATE_TOKEN_DATA';
  payload: Pick<IAuthState, 'access'>;
};

type SET_USER_PROFILE = {
  type: 'SET_USER_PROFILE';
  payload: Pick<IAuthState, 'userProfile'>;
};

type SET_SCREENING_LOADER = {
  type: 'SET_SCREENING_LOADER';
  payload: Pick<IAuthState, 'screeningLoader'>;
};

type SET_TAB_STATE = {
  type: 'SET_TAB_STATE';
  payload: Pick<IAuthState, 'isTabVisible'>;
};

type SET_ACCOUNT_PERMISSIONS_STATE = {
  type: 'SET_ACCOUNT_PERMISSIONS_STATE';
  payload: Pick<IAuthState, 'accountPermissions'>;
};

type LOGOUT = {
  type: 'LOGOUT';
  payload: Partial<IAuthState>;
};

type Actions =
  | SET_TOKEN_DATA
  | UPDATE_TOKEN_DATA
  | SET_USER_PROFILE
  | SET_SCREENING_LOADER
  | SET_TAB_STATE
  | SET_ACCOUNT_PERMISSIONS_STATE
  | LOGOUT;

// export const resetLocalStorage = () => {
//   localStorage.removeItem("last-active");
//   localStorage.removeItem("keepLogged");
//   localStorage.removeItem("access");
//   localStorage.removeItem("refresh");
// };
// export const setLocalStorage = (
//   currentTime: any,
//   access: any,
//   refresh: any
// ) => {
//   localStorage.setItem("last-active", currentTime);
//   localStorage.setItem("access", access);
//   localStorage.setItem("refresh", refresh);
// };

// Our reducer function that uses a switch statement to handle our actions
const authStateReducer = (state: IAuthState, action: Actions): IAuthState => {
  const { type, payload } = action;

  switch (type) {
    case 'SET_TOKEN_DATA':
      return {
        ...state,
        decoded: jwtDecode(payload.access),
        ...payload,
      };
    case 'UPDATE_TOKEN_DATA':
      return {
        ...state,
        ...payload,
        decoded: jwtDecode(payload.access),
      };
    case 'SET_USER_PROFILE':
      return {
        ...state,
        userProfile: payload.userProfile,
      };
    case 'SET_SCREENING_LOADER':
      return {
        ...state,
        screeningLoader: payload.screeningLoader,
      };
    case 'SET_TAB_STATE':
      return {
        ...state,
        isTabVisible: payload.isTabVisible,
      };
    case 'SET_ACCOUNT_PERMISSIONS_STATE':
      return {
        ...state,
        accountPermissions: payload.accountPermissions,
      };
    case 'LOGOUT':
      return {
        ...initialAuthData,
        ...payload,
      };
    default:
      return state;
  }
};

const useAuthStateValue = (props: IUseAuthStateProps) => {
  const { initialState = initialAuthData, onSignOut } = props;
  const [tabValue, setTabStorage] = useLocalStorage('isTabVisible', initialState.isTabVisible);
  const { isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
  const { pathname } = useRouter();
  const auth0Logout = useAuth0().logout;
  // const [access, setAccess, removeAccess] = useLocalStorage<string>(accessTokenKey, null, {
  //   raw: true,
  // });

  const [internalState, dispatch] = useReducer(authStateReducer, {
    ...initialState,
    userProfile: { ...initialState.userProfile },
    isTabVisible: tabValue ?? initialState.isTabVisible ?? true,
  });

  const state = useMemo((): IAuthState => {
    return {
      ...internalState,
      // access: accessTokenKey,
    };
  }, [internalState]);

  useEffect(() => {
    if (state.isTabVisible) {
      setTabStorage(true);
      document.documentElement.style.setProperty('--dashboard-tabs-height', '54.9px');
    } else {
      setTabStorage(false);
      document.documentElement.style.setProperty('--dashboard-tabs-height', '0px');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.isTabVisible]);

  useEffect(() => {
    if (isDetailPageWithoutSearch() && state.isTabVisible) {
      setTabState({ isTabVisible: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const setToken = useCallback(
    (data: SET_TOKEN_DATA['payload']) => {
      dispatch({
        type: 'SET_TOKEN_DATA',
        payload: data,
      });
      if (getUrlEnvironment() === 'dev') setTokenCookie(data?.access);
    },
    [dispatch]
  );

  const setUser = useCallback(
    (data: SET_USER_PROFILE['payload']) => {
      dispatch({
        type: 'SET_USER_PROFILE',
        payload: data,
      });
    },
    [dispatch]
  );

  const logout = useCallback(
    (data: LOGOUT['payload']) => {
      dispatch({
        type: 'LOGOUT',
        payload: data,
      });

      auth0Logout({ logoutParams: { returnTo: window.location.origin } });
      onSignOut?.();
    },
    [dispatch, onSignOut, auth0Logout]
  );

  const loginSilently = useCallback(async () => {
    let token = null;
    try {
      token = await getAccessTokenSilently();
    } catch {
      toast.error('Session expired, please login again.');
      logout({});
    }
    setToken({ access: token || '' });
    return token;
  }, [logout, getAccessTokenSilently, setToken]);

  const getToken = useCallback(async () => {
    if ((state.access && state.decoded?.exp * 1000 > Date.now()) || !isAuthenticated) {
      return state.access;
    }
    return loginSilently();
  }, [state.access, state.decoded?.exp, isAuthenticated, loginSilently]);

  const getScreeningLoader = useCallback(async () => {
    return state.screeningLoader;
  }, [state.screeningLoader]);

  const setScreeningLoader = useCallback(
    (data: SET_SCREENING_LOADER['payload']) => {
      dispatch({
        type: 'SET_SCREENING_LOADER',
        payload: data,
      });
    },
    [dispatch]
  );

  const setTabState = useCallback(
    (data: SET_TAB_STATE['payload']) => {
      dispatch({
        type: 'SET_TAB_STATE',
        payload: data,
      });
    },
    [dispatch]
  );

  const setAccountPermissionsState = useCallback(
    (data: SET_ACCOUNT_PERMISSIONS_STATE['payload']) => {
      dispatch({
        type: 'SET_ACCOUNT_PERMISSIONS_STATE',
        payload: data,
      });
    },
    [dispatch]
  );

  return {
    state,
    isAuthenticated,
    isLoading,
    dispatch,
    setToken,
    setUser,
    logout,
    loginSilently,
    getToken,
    setTabState,
    getScreeningLoader,
    setScreeningLoader,
    setAccountPermissionsState,
  };
};

const [AuthProvider, useAuth] = constate(useAuthStateValue);
AuthProvider.displayName = 'AuthProvider';

const Auth0ProviderWithRedirect = ({ children }: { children: React.ReactNode }) => {
  const domain = import.meta.env.VITE_AUTH0_DOMAIN || '';
  const clientId = import.meta.env.VITE_AUTH0_CLIENT_ID || '';
  const audience = import.meta.env.VITE_AUTH0_AUDIENCE || '';
  const redirectUri = window.location.origin + import.meta.env.VITE_AUTH0_REDIRECT_URI || '';

  const navigate = useNavigate();

  const onRedirectCallback = useCallback(
    (appState) => {
      navigate(appState?.returnTo || '/', { replace: true });
    },
    [navigate]
  );

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{
        audience,
        redirect_uri: redirectUri,
      }}
      onRedirectCallback={onRedirectCallback}
      useCookiesForTransactions={true}>
      {children}
    </Auth0Provider>
  );
};

export { Auth0ProviderWithRedirect, AuthProvider, useAuth };
