import React, { useEffect, useMemo, useRef, useState } from 'react';
import EmptyState from '../../ui/components/States/Empty';
import classNames from 'classnames';
import * as d3 from 'd3';
import { colorDarkDefault, colorsDark } from '../../utils/helpers/drawCanvasElements';
import { IAggregatedResult, INodeInfo } from '../../api/dtos/address';

interface DonutChartProps {
  inputs: Array<IAggregatedResult>;
  outputs: Array<IAggregatedResult>;
  incomingNodeInfo: INodeInfo;
  outgoingNodeInfo: INodeInfo;
  selectedEntities: Array<string>;
  rowInputs: Array<IAggregatedResult>;
  rowOutputs: Array<IAggregatedResult>;
  currency?: string;
  group: string;
  type: 'address' | 'transaction' | 'customer';
  id: string;
  isDestinationTag: boolean;
  isLoading: boolean;
  inputsType?: 'deposit' | 'withdrawal';
}
const nf = Intl.NumberFormat('en-US', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

const DonutChart: React.FC<DonutChartProps> = (props) => {
  const {
    inputs: inputsData,
    outputs: outputsData,
    rowInputs,
    rowOutputs,
    group = 'type',
    type,
    incomingNodeInfo,
    selectedEntities,
    outgoingNodeInfo,
    id = 'sankey',
    isLoading,
    inputsType,
  } = props;
  const hasSelectedEntities = selectedEntities.length > 0;

  const reward = useMemo(
    () => ({
      tag_type_verbose: 'Rewards',
      tag_subtype_verbose: 'Rewards',
      tag_name_verbose: 'Rewards',
      total_value_usd: incomingNodeInfo?.total_incoming_reward_usd || '0',
      exposure_type: 'direct',
    }),
    [incomingNodeInfo?.total_incoming_reward_usd]
  );

  const fee = useMemo(
    () => ({
      tag_type_verbose: 'Fee',
      tag_subtype_verbose: 'Fee',
      tag_name_verbose: 'Fee',
      total_value_usd: outgoingNodeInfo?.total_outgoing_fee_usd || '0',
      exposure_type: 'direct',
    }),
    [outgoingNodeInfo?.total_outgoing_fee_usd]
  );
  const inputs = useMemo(
    () => (hasSelectedEntities ? inputsData : inputsData?.concat(type === 'address' ? reward : fee)),
    [fee, hasSelectedEntities, inputsData, reward, type]
  );

  const outputs = useMemo(
    () => (hasSelectedEntities ? outputsData : outputsData?.concat(type === 'address' ? fee : reward)),
    [fee, hasSelectedEntities, outputsData, reward, type]
  );

  const [show, setShow] = useState(false);
  const [noInput, setNoInput] = useState(true);
  const [noOutput, setNoOutput] = useState(true);
  const [rowInputTotal, setRowInputTotal] = useState(null);
  const [rowOutputTotal, setRowOutputTotal] = useState(null);
  const [inputData, setInputData] = useState(null);
  const [outputData, setOutputData] = useState(null);

  useEffect(() => {
    setShow(inputs?.length > 0 || outputs?.length > 0);
    if (!inputs?.length && !outputs?.length) return;
    setNoInput(!inputs?.length || !inputs.some((i) => Number(i.total_value_usd) > 0));
    setNoOutput(!outputs?.length || !outputs.some((i) => Number(i.total_value_usd) > 0));

    // Recalculate rowInputTotal
    const getRowTotal = (list: IAggregatedResult[]) => {
      if (!list) return null;
      const data = list.filter((item) => {
        return item.exposure_type === 'direct';
      });
      let total = 0;
      data.forEach((item) => {
        if (item.tag_type_verbose !== 'Rewards' && item.tag_type_verbose !== 'Fee') {
          total += Number(item.total_value_usd) || 0;
        }
      });
      return total;
    };
    setRowInputTotal(getRowTotal(rowInputs));
    setRowOutputTotal(getRowTotal(rowOutputs));
    // Recalculate inputData and outputData
    const parseData = (list: IAggregatedResult[], totalValue) => {
      list = list.sort(function (a, b) {
        if (a.tag_type_verbose > b.tag_type_verbose) {
          return -1;
        }
        if (a.tag_type_verbose < b.tag_type_verbose) {
          return 1;
        }
        return 0;
      });
      const keysData = {};
      const unknown = [];
      const genData = [];
      let unknownValue = 0;
      let indirectValue = 0;
      let directValue = 0;

      // total without fees and rewards
      let counterPartyDirect = 0;
      let counterPartyIndirect = 0;

      let nameProp;
      if (group === 'type') {
        nameProp = 'tag_type_verbose';
      } else if (group === 'subtype') {
        nameProp = 'tag_subtype_verbose';
      } else {
        nameProp = 'tag_name_verbose';
      }

      list.forEach((item) => {
        let tooltip;
        if (group === 'type') {
          tooltip = item.tag_type_verbose || 'Unknown';
        } else if (group === 'name') {
          tooltip = item.tag_name_verbose || 'Unknown';
        } else if (group === 'subtype') {
          tooltip =
            item.tag_type_verbose + item.tag_subtype_verbose
              ? `${item.tag_type_verbose || 'Unknown'}: ${item.tag_subtype_verbose || 'Unknown'}`
              : 'Unknown';
        } else {
          const tooltipText =
            item.tag_name_verbose +
            ' (' +
            item.tag_type_verbose +
            (item.tag_subtype_verbose ? ' -> ' + item.tag_subtype_verbose : '') +
            ')';
          tooltip = tooltipText || 'Unknown';
        }
        if (item[nameProp]) {
          const key = `${item[nameProp]} - ${item.exposure_type}`;
          let dataItem = keysData[key];
          if (!dataItem) {
            const tagType = item.tag_type_verbose;
            keysData[key] = dataItem = {
              name: item[nameProp],
              value: Number(item.total_value_usd),
              tooltip,
              color: colorsDark[tagType] ? colorsDark[tagType] : colorDarkDefault,
            };
            if (item.exposure_type === 'direct') {
              if (item.tag_type_verbose !== 'Rewards' && item.tag_type_verbose !== 'Fee') {
                counterPartyDirect += Number(dataItem.value);
              }
              directValue += parseFloat(dataItem.value);
              genData.push(dataItem);
            } else {
              if (item.tag_type_verbose !== 'Rewards' && item.tag_type_verbose !== 'Fee') {
                counterPartyIndirect += Number(dataItem.value);
              }
              indirectValue += parseFloat(dataItem.value);
              unknown.push(dataItem);
            }
          } else {
            dataItem.value += Number(item.total_value_usd);
            if (item.exposure_type === 'direct') {
              if (item.tag_type_verbose !== 'Rewards' && item.tag_type_verbose !== 'Fee') {
                counterPartyDirect += Number(item.total_value_usd);
              }
              directValue += Number(item.total_value_usd);
            } else {
              if (item.tag_type_verbose !== 'Rewards' && item.tag_type_verbose !== 'Fee') {
                counterPartyIndirect += Number(item.total_value_usd);
              }
              indirectValue += Number(item.total_value_usd);
            }
          }
        } else if (item.exposure_type === 'direct') {
          unknownValue += Number(item.total_value_usd);
        }
      });

      if (unknown.length) {
        genData.push({
          tooltip: 'Unknown',
          name: 'Unknown',
          children: unknown,
          color: colorDarkDefault,
        });
      } else if (unknownValue > 0) {
        // Subtract fee/rewards from unknown
        unknownValue = Math.max(
          0,
          unknownValue - (genData.find((i) => ['Rewards', 'Fee'].includes(i.name))?.value ?? 0)
        );
        genData.push({
          tooltip: 'Unknown',
          name: 'Unknown',
          value: unknownValue,
          color: colorDarkDefault,
        });
      }

      return {
        name: 'Root',
        children: genData,
        counterPartyDirect,
        counterPartyIndirect,
        indirectValue: indirectValue,
        directValue: directValue,
        totalValue: parseFloat(totalValue),
        indirectValuePercentage: totalValue ? (100 / totalValue) * counterPartyIndirect : 0,
        directValuePercentage: totalValue ? (100 / totalValue) * counterPartyDirect : 0,
      };
    };
    !noInput && setInputData(parseData(inputs, rowInputTotal));
    !noOutput && setOutputData(parseData(outputs, rowOutputTotal));
  }, [
    group,
    inputs,
    isLoading,
    outputs,
    rowInputTotal,
    rowInputs,
    rowOutputTotal,
    rowOutputs,
    noInput,
    noOutput,
  ]);

  const chartIncoming = useRef<HTMLDivElement>();
  const chartOutgoing = useRef<HTMLDivElement>();

  const directIncomingValue = `$${nf.format(inputData?.counterPartyDirect)} (${nf.format(
    inputData?.directValuePercentage
  )}%)`;
  const indirectIncomingValue = `$${nf.format(inputData?.counterPartyIndirect)} (${nf.format(
    inputData?.indirectValuePercentage
  )}%)`;
  const directOutgoingValue = `$${nf.format(outputData?.counterPartyDirect)} (${nf.format(
    outputData?.directValuePercentage
  )}%)`;
  const indirectOutgoingValue = `$${nf.format(outputData?.counterPartyIndirect)} (${nf.format(
    outputData?.indirectValuePercentage
  )}%)`;

  useEffect(() => {
    if (!show || isLoading) return;
    const div = d3
      .select('body')
      .append('div')
      .attr(
        'class',
        'absolute bg-white z-50 w-auto pointer-events-none rounded-xl shadow-md p-4 font-semibold border border-gray-300'
      )
      .style('opacity', '0')
      .style('display', 'none');

    const drawChart = (id, data, tooltipType) => {
      if (!data) return;
      const { totalValue, directValue, indirectValue } = data;
      const value = parseFloat(totalValue) - parseFloat(directValue) - parseFloat(indirectValue);
      data.children = data.children.map((item) => {
        if (item.name === 'Unknown' && item.children?.length > 0 && value > 0) {
          item.children.unshift({
            color: 'transparent',
            name: 'Unknown',
            tooltip: 'Unknown',
            value,
          });
        }
        return item;
      });

      const partition = (data) => {
        const root = d3.hierarchy(data).sum((d) => d.value);
        return d3.partition().size([2 * Math.PI, root.height + 1])(root);
      };

      const width = inputsType ? 450 : 400;
      const radius = width / 6;
      const arc = d3
        .arc()
        .startAngle((d) => d.x0)
        .endAngle((d) => d.x1)
        .padAngle((d) => Math.min((d.x1 - d.x0) / 2, 0.01))
        .padRadius(radius * 1.5)
        .innerRadius((d) => (d.y0 > 1 || inputsType ? d.y0 * radius + 4 : d.y0 * 0))
        .outerRadius((d) => Math.max(d.y0 * radius, d.y1 * radius - 4));

      const root = partition(data);

      root.each((d) => (d.current = d));

      const svg = d3.select(id).append('svg');
      svg.attr('viewBox', [0, 0, width, width]).style('font', '10px sans-serif');

      const g = svg.append('g').attr('transform', `translate(${width / 2},${width / 2})`);
      const path = g
        .append('g')
        .selectAll('path')
        .data(root.descendants().slice(1))
        .join('path')
        .attr('fill', (d) => {
          return d.data.color;
        })
        .attr('fill-opacity', () => 1)
        .attr('d', (d) => arc(d.current));

      path
        .style('cursor', 'pointer')
        .on('mouseover', (d) => {
          const node = d3.select(d.srcElement).data()[0];
          const tooltip = `${tooltipType === 'sent' ? 'Sent' : 'Received'} US$${nf.format(
            Number(node.value)
          )} ${['Rewards', 'Fee'].includes(node.data.tooltip) ? 'as' : 'to'} ${node.data.tooltip}`;

          div
            .style('opacity', 1)
            .style('display', 'block')
            .style('position', 'absolute')
            .style('left', d.pageX + 20 + 'px')
            .style('top', d.pageY + 20 + 'px')
            .style('z-index', 100000)
            .html(tooltip);
        })
        .on('mouseout', function () {
          div.style('opacity', 0).style('display', 'none');
        })
        .on('mousemove', function (d) {
          div.style('left', d.pageX + 20 + 'px').style('top', d.pageY + 20 + 'px');
        });
    };
    if (chartIncoming?.current) chartIncoming.current.innerHTML = '';
    if (chartOutgoing?.current) chartOutgoing.current.innerHTML = '';
    drawChart(`#${id}Incoming`, inputData, 'received');
    drawChart(`#${id}Outgoing`, outputData, 'sent');
    return () => {
      div && div.remove();
    };
  }, [inputsType, id, inputData, isLoading, outputData, show]);
  if (isLoading) return null;
  if (!show) return <EmptyState />;

  return (
    <div className='container'>
      <div className={classNames({ 'grid grid-cols-2': !inputsType }, 'justify-items-center')}>
        {!inputsType || inputsType === 'deposit' ? (
          <div
            className={classNames(
              'border-gray relative w-full border-r',
              'incoming',
              noInput ? 'noData' : 'donut'
            )}>
            {noInput ? (
              <EmptyState />
            ) : (
              <>
                <div
                  ref={chartIncoming}
                  className='mx-auto'
                  style={
                    inputsType
                      ? {
                          width: '400px',
                          height: '400px',
                        }
                      : {
                          width: '300px',
                          height: '350px',
                        }
                  }
                  id={`${id}Incoming`}></div>
                <img
                  className={classNames(
                    'absolute left-1/2 -ml-6 h-12 w-12',
                    inputsType ? 'top-44' : 'top-32'
                  )}
                  src='/incoming.svg'
                  title='Incoming'
                />
                {inputsType && (
                  <div className='font absolute left-1/2 top-56 -ml-10 text-2xs font-medium'>
                    Incoming Funds
                  </div>
                )}

                <div className={classNames({ 'px-60': inputsType }, 'text-xs')}>
                  <div className='px-5'>
                    <p className='text-gray-700'>Total Incoming</p>
                    <p className='font-semibold'>${nf.format(rowInputTotal)}</p>
                  </div>
                  <div className='my-3 grid grid-cols-2'>
                    <div className='flex-1 overflow-hidden border-r-2 px-5' title={directIncomingValue}>
                      <p className='text-gray-700'>Direct Exposure</p>
                      <p className='font-semibold'>{directIncomingValue}</p>
                    </div>
                    <div className='flex-1 overflow-hidden bg-white px-5' title={indirectIncomingValue}>
                      <p className='text-gray-700'>Indirect Exposure</p>
                      <p className='font-semibold'>{indirectIncomingValue}</p>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        ) : null}

        {!inputsType || inputsType === 'withdrawal' ? (
          <div className={classNames('relative w-full', noOutput ? 'noData' : 'donut')}>
            {noOutput ? (
              <EmptyState />
            ) : (
              <>
                <div
                  ref={chartOutgoing}
                  className='mx-auto'
                  style={
                    inputsType
                      ? {
                          width: '400px',
                          height: '400px',
                        }
                      : {
                          width: '300px',
                          height: '350px',
                        }
                  }
                  id={`${id}Outgoing`}></div>
                <img
                  src='/outgoing.svg'
                  className={classNames(
                    'absolute left-1/2 -ml-6 h-12 w-12',
                    inputsType ? 'top-44' : 'top-32'
                  )}
                  title='Outgoing'
                />
                {inputsType && (
                  <div className='font absolute left-1/2 top-56 -ml-10 text-2xs font-medium'>
                    Outgoing Funds
                  </div>
                )}
                <div className={classNames({ 'px-60': inputsType }, 'text-xs')}>
                  <div className='px-5'>
                    <p className='text-gray-700'>Total Outgoing</p>
                    <p className='font-semibold'>${nf.format(rowOutputTotal)}</p>
                  </div>
                  <div className='my-3 grid grid-cols-2'>
                    <div className='flex-1 overflow-hidden border-r-2 px-5' title={directOutgoingValue}>
                      <p className='text-gray-700'>Direct Exposure</p>
                      <p className='font-semibold'>{directOutgoingValue}</p>
                    </div>
                    <div className='flex-1 px-5' title={indirectOutgoingValue}>
                      <p className='overflow-hidden text-gray-700'>Indirect Exposure</p>
                      <p className='font-semibold'>{indirectOutgoingValue}</p>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default DonutChart;
