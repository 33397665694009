/* This example requires Tailwind CSS v2.0+ */
import { FC, Fragment, ReactNode } from 'react';
import { Dialog, Transition, TransitionChild } from '@headlessui/react';
import { X } from '@phosphor-icons/react';
import classNames from 'classnames';

export interface BasicModalProps {
  open: boolean;
  onClose: (open: boolean) => void;
  modalTitle?: string;
  modalBody?: ReactNode;
  modalFooter?: ReactNode;
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  customBodyClass?: string;
  customFooterClass?: string;
  onPressEnter?: (event: React.KeyboardEvent) => void;
}

const BasicModal: FC<BasicModalProps> = (props) => {
  const { open, onClose, modalTitle, modalBody, modalFooter, size = 'md', onPressEnter } = props;

  const widthClasses = {
    xs: 'w-1/4', // 25%
    sm: 'w-4/12', // 33.33%
    md: 'w-6/12', // 50%
    lg: 'w-9/12', // 75%
    xl: 'w-11/12', // 91.666667%
  };
  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      onPressEnter?.(event);
    }
  };

  return (
    <Transition show={!!open} as={Fragment}>
      <Dialog
        as='div'
        onKeyDown={handleKeyDown}
        className='relative z-[1099] overflow-y-auto'
        onClose={onClose}>
        <div className='flex min-h-screen items-center justify-center px-4 pb-20 pt-4 text-center sm:block sm:p-0'>
          <TransitionChild
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'>
            <div className='fixed inset-0 bg-gray-500/75 transition-opacity' aria-hidden='true' />
          </TransitionChild>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className='hidden sm:inline-block sm:h-screen sm:align-middle' aria-hidden='true'>
            &#8203;
          </span>
          <TransitionChild
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            enterTo='opacity-100 translate-y-0 sm:scale-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100 translate-y-0 sm:scale-100'
            leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'>
            <div className='fixed inset-0 w-screen overflow-y-auto'>
              <div className='flex min-h-full items-center justify-center p-4'>
                <Dialog.Panel
                  className={`${widthClasses[size]} inline-block rounded-lg bg-white text-left align-middle shadow-xl transition-all`}>
                  {/* This is modal content */}
                  <div className='relative'>
                    {/* This is modal close button */}
                    <div className='absolute right-0 top-0 block pr-4 pt-4'>
                      <button
                        type='button'
                        className='rounded-md border-0 bg-transparent text-gray-400 hover:text-gray-500 focus:outline-none'
                        onClick={() => onClose(false)}>
                        <span className='sr-only'>Close</span>
                        <X className='size-6' aria-hidden='true' />
                      </button>
                    </div>
                    {/** This is modal header */}
                    <div className={`rounded-lg p-4 text-left ${modalTitle ? 'bg-gray-50' : ''}`}>
                      <Dialog.Title
                        as='h3'
                        className={classNames(
                          size === 'xs' ? 'text-xs font-medium' : 'text-base font-medium',
                          'text-gray-500'
                        )}>
                        {modalTitle}
                      </Dialog.Title>
                    </div>
                    {/** This is modal body */}
                    <div className={props.customBodyClass ? props.customBodyClass : 'px-4 py-6'}>
                      {modalBody}
                    </div>
                  </div>
                  {/* This is modal footer / buttons container */}
                  {modalFooter && (
                    <div
                      className={
                        props.customFooterClass
                          ? props.customFooterClass
                          : `rounded-b-lg px-4 py-3 ${modalFooter ? 'bg-gray-50' : ''}`
                      }>
                      {modalFooter}
                    </div>
                  )}
                </Dialog.Panel>
              </div>
            </div>
          </TransitionChild>
        </div>
      </Dialog>
    </Transition>
  );
};

export default BasicModal;
