import * as React from 'react';

import { IAddressResponse } from '../../../api/dtos/address';
import Alerts from '../../common/Alerts';
import Popover from '../../../ui/components/Popover/Popover';

interface Props {
  address: IAddressResponse;
  setAlerts: (alerts: number) => void;
}

const OverviewLightChains: React.FC<Props> = ({ address, setAlerts }) => {
  return (
    <div className='flex flex-col gap-3 p-4'>
      <div className='-m-4 mb-2 border-b bg-amber-200 p-2 py-4 text-center text-base font-semibold text-black'>
        <div className='flex justify-center'>
          {address.currency_verbose} - {address.currency_short} - Sanctions Screening Only
          <Popover
            isBasic
            referenceContent={
              <div className='ml-1 flex size-5 cursor-help justify-center rounded-full bg-gray-200'>i</div>
            }
            popoverContent={
              <div className='w-56 text-sm'>
                Entities that are monitored on sanctions screening only blockchains will be screened only for
                sanctions-related activity. For more information, click{' '}
                <a
                  className='text-blue-500'
                  target='_blank'
                  href='https://docs.merklescience.com/docs/full-coverage-vs-sanctions-screening'
                  rel='noreferrer'>
                  here
                </a>
                .
              </div>
            }
          />
        </div>
      </div>
      <Alerts id={address.id} type='address' setAlerts={setAlerts} />
    </div>
  );
};

export default OverviewLightChains;
