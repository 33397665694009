import { useRouter } from '../../../../modules/router/RouterProvider';
import { useState } from 'react';
import { integrationApi, useIntegrationsGet } from '../../../../api/integration';
import { IIntegration, getIntegrationData } from '../../../../utils/helpers/integrations';
import CompassLoader from '../../../../components/ui/components/Loader/CompassLoader';
import { Button, DropdownOption } from '../../../../ui';
import { sanitizeHtml } from '../../../../utils/helpers/common';
import React from 'react';
import SimpleDropdown from '../../../../components/ui/components/Dropdown/SimpleDropdown';
import { CaretDown } from '@phosphor-icons/react';
import SlackForm from '../../../../components/Settings/Integrations/SlackForm';
import SlackHow from '../../../../components/Settings/Integrations/SlackHow';
import { useMutation, useQueryClient } from 'react-query';
import { IIntegrationResult, IIntegrationUpdateRequest } from '../../../../api/dtos/integration';
import WebhookForm from '../../../../components/Settings/Integrations/WebhookForm';
import FireblocksForm from '../../../../components/Settings/Integrations/FireblocksForm';
import { toast } from 'react-toastify';
import { AxiosResponse } from 'axios';
import { startCase } from 'lodash';
import WebhookLogs from '../../../../components/Settings/Integrations/WebhookLogs';
import FireblocksLogs from '../../../../components/Settings/Integrations/FireblocksLogs';
import IntegrationBadge from '../../../../components/ui/components/Badge/IntegrationBadge';
import FileIcon from '@/assets/icons/file.svg';
import MailIcon from '@/assets/icons/mail.svg';

interface IntegrationDetailsProps {
  isFireblocksEnabled?: boolean;
  apiProxy?: string;
  slackInstallationUrl?: string;
}
export interface IIntegrationProps {
  data: IIntegrationResult;
  type: string;
  onSubmit: (data: IIntegrationUpdateRequest) => Promise<AxiosResponse<IIntegrationResult>>;
  apiProxy?: string;
  onCancel?: () => void;
}

const Form: React.FC<IIntegrationProps> = (props) => {
  const { data, type } = props;
  if (type === 'slack') return <SlackForm {...props} />;
  if (type === 'webhook') return <WebhookForm {...props} />;
  if (type === 'fireblocks' && data?.is_active)
    return <FireblocksForm {...props} apiProxy={props.apiProxy} />;
  return null;
};

const IntegrationDetails: React.FC<IntegrationDetailsProps> = ({
  isFireblocksEnabled,
  slackInstallationUrl,
  apiProxy,
}) => {
  const { getParams, navigate } = useRouter();
  const integrationType = getParams().type || getParams().integrationType;

  const queryClient = useQueryClient();

  const [showForm, setShowForm] = useState(false);
  const [showLogs, setShowLogs] = useState(false);

  const isFormOpen = () => {
    return showForm || integrationType === 'fireblocks';
  };

  const { data, isFetching } = useIntegrationsGet();
  const onSuccess = () => {
    toast.success(`${startCase(integrationType)} Settings Updated`);
    queryClient.invalidateQueries(['integrationApi']);
  };
  const onError = () => {
    toast.error(`Error Updating ${startCase(integrationType)} Settings`);
  };

  const { mutateAsync: updateIntegration, isLoading: isUpdateIntegrationLoading } = useMutation(
    integrationApi.updateIntegration,
    {
      onSuccess,
      onError,
    }
  );
  const { mutateAsync: createIntegration, isLoading: isCreateIntegrationLoading } = useMutation(
    integrationApi.createIntegration,
    {
      onSuccess,
      onError,
    }
  );
  const isLoading = isUpdateIntegrationLoading || isCreateIntegrationLoading;

  const saveIntegration = async (data: IIntegrationUpdateRequest) => {
    if (data?.id) {
      return await updateIntegration(data);
    }
    return await createIntegration(data);
  };

  const integrations = getIntegrationData(data?.data?.results, isFireblocksEnabled);
  const currentIntegration = integrations.find(
    (integration: IIntegration) => integration.provider === integrationType
  );
  const integrationResponse = data?.data?.results.find((i) => i.provider === integrationType);

  const onClickAdd = () => {
    if (integrationType === 'slack') {
      window.location.href = slackInstallationUrl || import.meta.env.VITE_SLACK_INSTALLATION_URL;
    } else if (integrationType === 'webhook') {
      setShowForm(true);
    } else if (integrationType === 'fireblocks') {
      changeIntegrationStatus();
    }
  };

  const changeIntegrationStatus = async () => {
    await saveIntegration({
      ...integrationResponse,
      provider: integrationType,
      is_active: !integrationResponse?.is_active,
    });
  };

  const onChangeActions = (option: DropdownOption) => {
    if (option.value === 'edit') {
      setShowForm(true);
    } else if (option.value === 'slack-settings') {
      setShowForm(true);
    } else if (option.value === 'deactivate') {
      setShowForm(false);
      changeIntegrationStatus().then(() =>
        toast.success(`${startCase(integrationType)} Integration Deactivated`)
      );
    } else if (option.value === 'logs') {
      setShowLogs(true);
    }
  };

  const options = [
    {
      label: 'Edit Configuration',
      value: 'edit',
      hidden: integrationType !== 'webhook',
    },
    {
      label: 'Notification Settings',
      value: 'slack-settings',
      hidden: integrationType !== 'slack',
    },
    {
      label: 'Deactivate Integration',
      value: 'deactivate',
    },
    {
      label: 'View Logs',
      value: 'logs',
      hidden: integrationType === 'slack',
    },
  ];

  const onGoBack = () => navigate('/settings/workspace/integrations');
  const onClickDocumentation = () => navigate('/docs/api');

  if (isFetching) return <CompassLoader />;

  return (
    <div className=''>
      <div onClick={onGoBack} className='mb-2 cursor-pointer text-sm text-gray-500 hover:underline'>
        &lt; All Integrations
      </div>
      <div className='grid grid-cols-12'>
        <div className='col-span-2 size-32 rounded-lg border p-7'>
          <img src={currentIntegration.image} className='' alt={currentIntegration.label} />
        </div>
        <div className='col-span-8 px-4'>
          <div className='mb-2 flex'>
            <div className='mr-1 text-[24px]'>{currentIntegration.label}</div>
            <IntegrationBadge
              className='my-auto'
              type={currentIntegration.provider}
              isEnabled={currentIntegration.isActive}
              isAdvanced={integrationResponse?.config?.is_advanced_integration_enabled}
            />
          </div>
          <div
            className='text-sm text-gray-500'
            dangerouslySetInnerHTML={{ __html: sanitizeHtml(currentIntegration.descriptionVerbose) }}
          />
        </div>
        <div className='col-span-2'>
          {!currentIntegration.isActive ? (
            <Button onClick={onClickAdd} disabled={isLoading}>
              Add Integration
            </Button>
          ) : (
            <SimpleDropdown
              onChange={onChangeActions}
              options={options}
              placeholder={
                <Button className='text-sm font-semibold' variant='secondary' disabled={isLoading}>
                  Actions <CaretDown weight='bold' size={16} className='ml-1' />
                </Button>
              }
            />
          )}
        </div>
      </div>
      {isFormOpen() && (
        <Form
          data={integrationResponse}
          onSubmit={saveIntegration}
          onCancel={() => setShowForm(false)}
          type={integrationType}
          apiProxy={apiProxy}
        />
      )}
      {integrationType === 'slack' && <SlackHow />}
      {/* Setup */}
      <div className='my-4 grid grid-cols-12 gap-4 divide-x border-t pt-6'>
        <div className='col-span-8'>
          <div className=''>
            <div className='mb-4 text-[24px] font-semibold'>How to setup?</div>
            {integrationType === 'fireblocks' && (
              <div className='mb-1 text-lg font-semibold'>Basic integration</div>
            )}
            <ol className='ml-4 list-decimal text-sm text-gray-500'>
              {currentIntegration.setup.map((item, i) => (
                <li key={i} dangerouslySetInnerHTML={{ __html: sanitizeHtml(item) }}></li>
              ))}
            </ol>
          </div>
          {integrationType === 'fireblocks' && (
            <div>
              <div className='mb-1 mt-4 text-lg font-semibold'>Advanced integration</div>
              <ol className='ml-4 list-decimal text-sm text-gray-500'>
                {currentIntegration.setupAdvanced.map((item, i) => (
                  <li key={i} dangerouslySetInnerHTML={{ __html: sanitizeHtml(item) }}></li>
                ))}
              </ol>
            </div>
          )}
        </div>
        <div className='col-span-4 pl-8'>
          <div className='mb-4 text-[24px] font-semibold'>Help</div>
          <div className='cursor-pointer text-sm font-medium text-blue-600'>
            <div className='flex py-1 hover:underline' onClick={onClickDocumentation}>
              <img src={FileIcon} alt='file' className='mx-2 my-auto size-4' />
              View API Documentation
            </div>
            <a href='mailto:support@merklescience.com' className='flex py-1 hover:underline'>
              <img src={MailIcon} alt='mail' className='mx-2 my-auto size-4' />
              Contact Merkle Science Support
            </a>
          </div>
        </div>
      </div>
      {integrationType === 'webhook' ? (
        <WebhookLogs modal={showLogs} setModal={setShowLogs} />
      ) : (
        <FireblocksLogs modal={showLogs} setModal={setShowLogs} />
      )}
    </div>
  );
};

export default IntegrationDetails;
